@use "../util/" as *;

.container {
  padding: 0 rem(25);
  max-width: rem(1110);
  margin: 0 auto;

  @include breakpoint(medium) {
    margin-bottom: rem(26);
  }

  @include breakpoint(large) {
    padding: 0;
    margin-bottom: rem(46);
  }
}
